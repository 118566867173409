import React, { useMemo } from "react";
import http from "@/services/http";

import { useQueryParams } from "@/services/hooks";
import { Table, Button, Result } from "antd";

import { usePaginatedQuery } from "react-query";

import { Link, useHistory, generatePath } from "react-router-dom";
import DeleteButton from "../../components/DeleteButton";
//import ChamadosBusca from "./ChamadosBusca";

const AulasList = () => {
	const history = useHistory();

	const [query, setQuery] = useQueryParams();

	const page = useMemo(() => Number(query.page || 1), [query]);

	const {
		status,
		resolvedData,
		latestData,
		error,
		isFetching,
		failureCount,
		refetch,
	} = usePaginatedQuery(
		["aulas", page, query.obs, query.data],
		(url, page, obs, data) => http.get(url, { params: { page, obs, data } })
	);

	if (status === "error") {
		return (
			<Result
				status="error"
				title="Aconteceu um erro inesperado"
				Title="Por favor recarregue a página"
				extra={[
					<Button
						type="primary"
						onClick={() => refetch()}
						loading={isFetching}
						key={"reload"}
					>
						Recarregar
					</Button>,
				]}
			/>
		);
	}

	const columns = [
		{
			title: "Nome",
			dataIndex: "nome",
			align: "left",
		},
		{
			title: "Vídeo",
			dataIndex: "video_url",
			align: "left",
			render(video_url) {
				return (
					<a target={"_blank"} href={video_url}>
						Abrir video
					</a>
				);
			},
		},
		{
			title: "Módulo",
			key: "modulo",
			align: "left",
			render(_, record) {
				return record?.modulo?._id
					? record?.modulo?.nome
					: "Sem modulo";
			},
		},
		{
			title: "Ordem",
			key: "ordem",
			align: "left",
			render(_, record) {
				return record?.ordem;
			},
		},

		{
			title: "Ações",
			key: "action",
			align: "right",
			render: (_, record) => (
				<span>
					<Link
						className="mr-5"
						to={generatePath("/aulas/editar/:id", {
							id: record._id,
						})}
					>
						Editar
					</Link>

					<DeleteButton id={record._id} query={"aulas"} />
				</span>
			),
		},
	];

	return (
		<>
			<div className="mb-2"></div>
			<Link to={"/aulas/add"}>
				<Button type="primary" className="mb-2">
					Adicionar
				</Button>
			</Link>

			<Table
				scroll={{ x: "100%" }}
				rowKey={"id"}
				dataSource={resolvedData ? resolvedData.docs : []}
				pagination={{
					pageSize: 15,
					total: resolvedData ? resolvedData.totalDocs : 0,
					defaultCurrent: page,
					current: page,
					onChange: (newPage) => {
						setQuery({ ...query, page: newPage });
					},
				}}
				columns={columns}
				loading={status === "loading" || isFetching}
			/>
		</>
	);
};

export default AulasList;
